// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$enable-dark-mode: true;
$enable-shadows: true;

$dark-color: #1c1c1c;

$success: #16db65;
