// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito'); // Variables
@import 'variables'; // Bootstrap
@import '~bootstrap/scss/bootstrap'; // Font awesome
@import url("../../public/css/font-awesome.min.css");

.bg-black {
    background-color: $dark-color !important;
}

.jumbotron-home {
    position: relative;
}

.jumbotron-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.jumbotron-video-text {
  color: white;
  text-shadow: 0px 0px 5px rgba(0,0,0,0.7);
  text-outline: 2px solid rgba(0,0,0,0.8);
}

.featured-in-section {
    // Responsive layout
    .container {
        @media (max-width: 767px) {
            padding-left: 15px;
            padding-right: 15px;
        }

        .row {
            margin-left: -15px;
            margin-right: -15px;

            > div {
                padding-left: 15px;
                padding-right: 15px;
                margin-bottom: 30px;
            }
        }
    }

    .featured-logo {
        height: 65px;
        /* Adjust the desired height */
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img {
            max-width: 100%;
            max-height: 60%;
            object-fit: contain;
            /* Use object-fit to control how the image is resized within the container */
        }
    }
}

.card {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.7);
    }
}

.card-points {
    .card {
        border: none;
        border-radius: 10px;
    }

    .card-header {
        color: #fff;
        border-radius: 10px 10px 0 0;
    }

    .card-title {
        margin: 0;
    }

    .card-body {
        padding: 1.5rem;

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        li {
            position: relative;
            padding-left: 25px;
            margin-bottom: 0.5rem;

            &:before {
                content: "\f058";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                position: absolute;
                left: 0;
                top: 0;
                color: $success;
            }
        }
    }
}

.card-yt {
    .embed-responsive {
        position: relative;
        height: 0;
        overflow: hidden;
        padding-bottom: calc(9 / 16 * 100%);
        /* 16:9 Aspect Ratio */
    }

    .embed-responsive-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* Set height to 100% */
    }
}

.social-media-icons {
    .list-inline-item {
        transition: color 0.2s ease-in-out;
    }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-image: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
}

.carousel-control-prev-icon:before {
    content: '\2039';
}

.carousel-control-next-icon:before {
    content: '\203a';
}
